<template>
  <portal to="dialog">
    <ws-dialog
        v-model="display"
        :title="$t(title)"
        :cancel-text="uploadFinished ? $t('Finish') : $t('Cancel')"
        hide-save
    >

      <ws-navigation
          v-model="navigation"
          :items="navigationSelect"
          no-number
      >
        <template #item.upload>
          <v-fade-transition>
            <!-- Uploading Window-->
            <div v-if="!uploadFinished">
              <h4 class="font-weight-regular text-center">{{ descriptionText }}</h4>
              <h4 class="mb-7 font-weight-regular text-center">
                {{ $t('ImportTableTemplateDescription') }}
                <a class="noUnderline linkHover" :style="`color : ${wsACCENT}`" href="westudy.ua">
                  {{ $t('ImportTableTemplateDescriptionLinkText') }}
                </a>
              </h4>
              <ws-file-uploader
                  @success="handleUpload"
                  :request-data="{
                        route : `import/${entity}`,
                        westudyPrivate : true,
                        data : body,
                  }"
                  xls
              />
              <h5 class="font-weight-regular text-center mt-3">Upload files in format .xls or .xlsx</h5>
            </div>
            <!-- Result preview -->
            <div v-else>
              <slot name="result-preview" :data="uploadResult">



                <div class="d-flex justify-center py-3">
                  <v-icon size="64" :color="wsACCENT">mdi-file</v-icon>
                </div>
                <h5 class="text-center font-weight-regular mb-3">{{ uploadResult.file }}</h5>

                <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedLight pa-3 py-5 mt-8">
                  <h4>{{ $t(CAPITALIZA_FIRST_CHAR(entity))  }} : {{ uploadResult.disciplines_created_count }}</h4>
                </v-sheet>




              </slot>
            </div>
          </v-fade-transition>


        </template>
        <template #item.review>
          <h3>Review your results</h3>
        </template>
      </ws-navigation>

    </ws-dialog>
  </portal>

</template>

<script>
export default {
  name: "wsFileImportDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    title : {
      type : String,
      default : 'ImportFromFile'
    },
    entity : {
      type : String,
      default : 'disciplines'
    },
    body : {
      type : Object,
      default : null
    }
  },
  data() {
    return {
      display : false,
      uploadFinished : false,
      navigation : 'upload',
      uploadResult : {}
    }
  },
  computed : {
    descriptionText() {
      let text = ''

      switch(this.entity) {
        case 'disciplines' : text = 'wsu.import.discipline.description';  break;
        case 'departments' : text = 'wsu.import.department.description';  break;
      }

      return this.$t(text)
    },
    navigationSelect() {
      return [
          { text : this.$t('UploadFile') , value : 'upload' },
          // { text : this.$t('ReviewResult')     , value : 'review' , disabled : !this.uploadFinished },
      ]
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
  },
  methods : {
    handleUpload(data) {

      this.uploadFinished = true
      this.uploadResult   = data
      this.$emit('success')

    },
    saveData() {
      this.notify('Import finished or closed')
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>